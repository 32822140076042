import { Injectable } from '@angular/core';
import { urlBanca} from '../intefaces/url';
import { Http,Headers } from '@angular/http';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { HttpErrorResponse  } from '@angular/common/http';
import { map, catchError, timestamp } from 'rxjs/operators';
import { Clinic } from '../intefaces/clinic.interface';
import { banca, envioBanca, responseBanca, urlBancaResponse } from '../intefaces/banca';
import { Observable } from 'rxjs';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class BancaService {
  uri = '';
  
  constructor(private http:HttpClient) {

    this.uri = urlBanca;

    
   }
  
 


   loginBanca(bancaLog: banca):Observable<responseBanca> {
   
  
    let body = bancaLog;
    this.uri = urlBanca;
    this.uri+= '/get_tokens_convenio_empresa'
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post<responseBanca>(this.uri, body);
  }
   extraerUrl(banca: envioBanca, idToken):Observable<urlBancaResponse> {
   
    this.uri = urlBanca;
    let body = banca;
    this.uri+= '/generate_url?'
   
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', idToken);
    return this.http.post<urlBancaResponse>(this.uri, body, {headers});
  }

 
}
