import { Injectable } from '@angular/core';
import { Http ,Headers } from '@angular/http';
import { url } from '../intefaces/url';
import { map, catchError } from 'rxjs/operators';
import { Details } from '../intefaces/details.interface';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { error } from 'protractor';
import swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  uri = '';

  //welli
  urlWelli ='https://uo4vk7pprd.execute-api.us-east-1.amazonaws.com/dev/';
  urlWelliProd = 'https://bqng73z8oh.execute-api.us-east-1.amazonaws.com/prod/';
  apiKey = 'kEZhlUWcdP7Vunv7jTaCxaxXQbsB7fFk13M6fH6D'
  apiKeyProd = 'bKFkzWGlui7QEmSHMnglca5WkkYbgYDpaY8dl7lC'

  //credifamilia
  urlCrediDev = 'https://dn6j3zl8mi.execute-api.us-east-1.amazonaws.com/dev/createRequest';
  apiKeyDevCredi= 'AGT1lPB07z9RUZqn7y6xG5bFFRUvs4zK5oTaAqe3'
  details: Details = {
    loanId: '',
    customerId: '',
    userId: ''
  }
  
  constructor(private http:HttpClient) { 

    this.uri = url;
  }

  changeDetails(deta:Details){
    this.details = deta;

  }
  estadoDetails(cuerpo:any):Observable<any>{
    this.uri = '';
    this.uri = url;
    this.uri += '/request/user'
    return this.http.post(this.uri,cuerpo)
  }
  getDetails(){
    return this.details;

  }
  putCustomer(customer:any):any{
    let request = customer;
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += '/customer'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.put(this.uri, body)

  }
  putLoan(loan:any):any{
    let request = loan;
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'request/update'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.put(this.uri, body, )
  }
  postDetails(detail:Details):any{
    let request = detail;
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'request/details'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body)
  }

  getAllRequest():any{
    let request = {
      userId: "NA"
    };
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'requests'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body)


  }
  saveRequestCredi(body){
      // let url = `${this.urlWelliProd}post-data`
      let url = this.urlCrediDev;
      // this.urlWelli = this.urlWelli + 'post-data';
          // 'x-api-key': this.apiKeyProd,
          // 'x-api-key': this.apiKey,
      let headers = new HttpHeaders({
        'x-api-key': this.apiKeyDevCredi,

        'content-Type': 'application/json',

      });
      return this.http.post(url,body,{headers})
  }

  saveRequestWelli(body:any){
    
    let url = `${this.urlWelliProd}post-data`
    // let url = this.urlWelli + 'post-data';
    // this.urlWelli = this.urlWelli + 'post-data';
       // 'x-api-key': this.apiKeyProd,
       // 'x-api-key': this.apiKey,
    let headers = new HttpHeaders({
      'x-api-key': this.apiKeyProd,
   
      'content-Type': 'application/json',

    });
    return this.http.post(url,body,{headers})

  }
  getRequestWelli(id:string){
     let url = `${this.urlWelliProd}get-data/${id}`
    //  let url = this.urlWelli +'get-data/' + id
    //  this.urlWelli = this.urlWelli +'get-data/' + id
    // 'x-api-key':this.apiKeyProd,
    // 'x-api-key':this.apiKey,
    let headers = new HttpHeaders({
     'x-api-key':this.apiKey,
      
      'content-Type': 'application/json',
    });
    return this.http.get(url,{headers})

  }
  getCustomer(cc:string):any{
    this.uri = '';
    this.uri = url;
    
    this.uri += 'request/get-customer?id='+ cc;

    // let headers = new Headers({
    //   'content-Type': 'application/json',
    //   'x-auth-token': localStorage.getItem("token")

      
    // });
    return this.http.get(this.uri)
  //   return this.http.get(this.uri).pipe(
  //     map(res=> res.json()),
  //     catchError(error =>{
  //       //console.log(error.status);
  //       swal({   title: "Ups!",   
  //       text: "No se encontraron resultados",
  //       type:"error",   
  //       timer: 2000,   
  //       showConfirmButton: false 
  //  });
  //       return  throwError(  error.status);
  //     })
  //   )
  }
  errorHandler(error: HttpErrorResponse) {
    //console.log(error);
    return  (error);
}
  getRequest(id:string):any{
    let request = {
      userId: id
    }; 



    
    this.uri = '';
    this.uri = url;
    let body = request;
    this.uri += 'requests'
    //console.log(body);
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body)
  }
}
