// src\app\services\websocket.service.ts
import { Injectable } from "@angular/core";
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// import ReconnectingWebSocket from "reconnecting-websocket";

const CHAT_URL = "wss://284nulv0gf.execute-api.us-east-1.amazonaws.com/dev";

export interface Message {
    action: string;
    message: string;
}
declare const $: any;
@Injectable({
    providedIn: 'root'
  })
export class WebsocketService {
    private subject: AnonymousSubject<MessageEvent>;
    public messages: Subject<Message>;
    timerId;
    constructor() {
        this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
            map(
                (response: MessageEvent): Message => {
               
                    console.log(response.data);
                    if(response.data === 'Echo: alive'){
                       
                    }else if(response.data === 'Echo: insert'){
                        
                    }
                    else if(response.data === 'Echo: new info'){
                       
                    }
                    else{
                        console.log('error')
                    }
                    
                    let data = (response.data)
                    return data;
                }
            )
        );
        
    }

    public disconect(){
        this.subject.closed = true;
        console.log(this.subject)
        this.messages.closed= true
        console.log(this.messages)
        
    }
    public reConect(){
        // let ws = new ReconnectingWebSocket(url );
            this.subject = null;
            this.messages = null
            this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(map(
                (response: MessageEvent): Message => {
               
                    console.log(response.data);
                    if(response.data === 'Echo: alive'){
                       
                    }else if(response.data === 'Echo: insert'){
                      
                    }else if(response.data === 'Echo: new info'){
                       
                    }
                    else{
                        console.log('error')
                    }
                    
                    let data = (response.data)
                    return data;
                }
            ))
      
    }
    public connect(url): AnonymousSubject<MessageEvent> {
        console.log(this.subject)
        if (!this.subject) {
            this.subject = this.create(url);
            console.log("Successfully connected: " + url);
        }
       
        return this.subject;
    }
    public onConnect(timeout = 20000){
        console.log('hola')
        console.log(this.subject)
        // let ws = new WebSocket(CHAT_URL);
        // let observable = new Observable((obs: Observer<MessageEvent>) => {
        //     ws.onmessage = obs.next.bind(obs);
        //     ws.onerror = obs.error.bind(obs);
        //     ws.onclose = obs.complete.bind(obs);
        //     return ws.close.bind(ws);
        // });
        // if (ws.readyState == ws.OPEN) {  
        //     ws.send('');  
        // }  
        // this.timerId = setTimeout(this.onConnect, timeout);  
    }
    public cancelKeepAlive() {  
        if (this.timerId) {  
            clearTimeout(this.timerId);  
        }  
    }
    private create(url): AnonymousSubject<MessageEvent> {
        let ws = new WebSocket(url );
       console.log(ws)
        let observable = new Observable((obs: Observer<MessageEvent>) => {
            ws.onmessage = obs.next.bind(obs);
            ws.onerror = obs.error.bind(obs);
            ws.onclose = obs.complete.bind(obs);
            return ws.close.bind(ws);
        });
        let observer = {
            error: null,
            complete: null,
            next: (data: Object) => {
                console.log('Message sent to websocket: ', data);
                let x:any  = data
                console.log(x)
                let y:Message = x;
                if(y.message == 'cerrar'){
                    console.log(' websocket close front')
                    ws.close(1000)
                }else{
                    if (ws.readyState === WebSocket.OPEN) {
                       
                        ws.send(JSON.stringify(data));
                        
                    }else{
                        console.log('se cerro')
                        this.reConect();
                        
                    }
                    
                }
            }
        };
        return new AnonymousSubject<MessageEvent>(observer, observable);
    }
    
}