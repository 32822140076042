import { Routes, RouterModule } from '@angular/router';
import { GeneralGuardGuard } from '../auth/general-guard.guard';
import { FormComponent } from '../form/form.component';
// import { HomeComponent } from './home/home.component';
// import { FormComponent} from './form/form.component';
// import { UsersComponent } from './users/users.component';
// import { UserComponent } from './users/user/user.component';
// import { OrderComponent } from './order/order.component';
// import { ReportsComponent } from './reports/reports.component';
// import { RequestComponent } from './request/request.component';
// import { AssignmentComponent } from './request/assignment/assignment.component';
// import { SearchComponent } from './search/search.component';
// import { MyRequestComponent } from './my-request/my-request.component';
// import { ClinicComponent } from './clinics/clinic/clinic.component';
// import { ClinicsComponent } from './clinics/clinics.component';
// import { RateComponent } from './rate/rate.component';
// import { RatesComponent } from './rate/rates/rates.component';
// import { GeneralGuardGuard} from './auth/general-guard.guard'

const routes: Routes = [
    // 
    // {path: 'Form', canActivate: [GeneralGuardGuard],component: FormComponent },
    // {path: 'Users',canActivate: [GeneralGuardGuard], component: UsersComponent },
    // {path: 'Request',canActivate: [GeneralGuardGuard], component: RequestComponent },
    // {path: 'Search',canActivate: [GeneralGuardGuard], component: SearchComponent },
    // {path: 'MyRequest',canActivate: [GeneralGuardGuard], component: MyRequestComponent },
    // {path: 'Request/:id',canActivate: [GeneralGuardGuard], component:AssignmentComponent },
    // {path: 'User/:id',canActivate: [GeneralGuardGuard], component: UserComponent },
    // {path: 'Clincs',canActivate: [GeneralGuardGuard], component: ClinicsComponent },
    // {path: 'Clinic/:id',canActivate: [GeneralGuardGuard], component: ClinicComponent },
    // {path: 'Rate',canActivate: [GeneralGuardGuard], component: RateComponent },
    // {path: 'Rate/:id',canActivate: [GeneralGuardGuard], component: RatesComponent },
    // {path: 'Order',canActivate: [GeneralGuardGuard], component: OrderComponent },
    // {path: 'Reports',canActivate: [GeneralGuardGuard], component: ReportsComponent },
    // { path: '**', pathMatch: 'full', redirectTo: 'Home'}
];
export class FeatureRoutingModule {}
export const APP_ROUTING_WORK_fLOW = RouterModule.forRoot(routes);
