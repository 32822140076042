import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebsocketService } from '../services/webSocket.service';
export interface Message {
  source: string;
  content: string;
}
declare const $: any;
@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styles: [
  ]
})

export class WorkflowComponent implements OnInit {
  private  client:Client;
  urlWebSocket = "wss://284nulv0gf.execute-api.us-east-1.amazonaws.com/production";
  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<Message>;
  timerId;
  myTimeOut;
  myTimeOut2;
  constructor(private router: Router, private WebsocketService: WebsocketService) {
    this.myTimeOut = setInterval(() => {
      this.webSocketOn(); 
    }, 540000);
    this.WebsocketService.messages.subscribe(data =>{

    })
    this.myTimeOut2 = setInterval(() => {
      this.limpiarTime(); 
    }, 6600000);
    // this.myTimeOut2 = setInterval(() => {
    //   this.limpiarTime(); 
    // }, 120000);
   
   }

  openConect(){
    // this.WebsocketService.reConect();
    this.WebsocketService = new WebsocketService();
    this.WebsocketService.messages.subscribe(data =>{

    })
  }
  imprimir(){
    console.log(5000)
  }
  keepAlive() { 
   
    setInterval(this.keepAlive,60000)
    
}
limpiarTime(){
  
  console.log('entro a cerrar front')
  let message = {
    action: '',
    message: ''
  };
  message.action = 'ping';
  message.message = 'cerrar';
  this.WebsocketService.messages.next(message);

  this.openConect();
 
}
ngOnDestroy(): void {
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
  clearInterval(this.myTimeOut);
  clearInterval(this.myTimeOut2);
  let message = {
    action: '',
    message: ''
  };
  message.action = 'ping';
  message.message = 'cerrar';
  this.WebsocketService.messages.next(message);
  console.log('destroy')
  this.webSocketOff();
}
webSocketOff(){
  console.log('eliminar')
  clearInterval(this.myTimeOut);
  clearInterval(this.myTimeOut2);
  
}
cancelKeepAlive() {  
  if (this.timerId) {  
      clearTimeout(this.timerId);  
  }  
}
  webSocketOn(){
    let message = {
      action: '',
      message: ''
    };
    message.action = 'ping';
    message.message = 'Hello';
    this.WebsocketService.messages.next(message);
    
  }

  ngOnInit(): void {
    this.router.navigate(['/dashboard'])
    this.client = new Client();
    
   
  }

}