import { Component, OnInit,ElementRef, OnDestroy } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { Login } from '../intefaces/login.interface';
import { LoginService } from '../services/login.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare interface Success{
  profile:string,
  userId:string
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit , OnDestroy {

  test: Date = new Date();
  private toggleButton: any;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  login:Login = {
    userName:'',
    password: ''
  }
  user: string = '';
  pasw: string = ''; 
  log:Success = {
    profile: '',
    userId: ''
  }
  constructor(private element: ElementRef, private router:Router,private loginServices:LoginService) {
      this.nativeElement = element.nativeElement;
      localStorage.setItem('iux','');
      localStorage.setItem('pro','');
      localStorage.setItem('usu', '');
      localStorage.clear();
      this.sidebarVisible = false;
  }

  validate() {
    // //console.log(this.user)
    this.login.userName = this.user;
    this.login.password = this.pasw;
    this.loginServices.validateLogin(this.login).subscribe(data=>{
     
      this.log  = data;
      localStorage.clear();
      localStorage.setItem('iux',this.log.userId);
      localStorage.setItem('pro',this.log.profile);
      localStorage.setItem('usu', this.user);
      swal({
        title: "Bienvenido, " +this.user ,
        text: "",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-success",
        type: "success",
        onAfterClose: () => this.router.navigate(['/dashboard'])
    }).catch(swal.noop)
    }, error =>{
      // //console.log('esto es un error');
      swal({   title: "Ups!",   
             text: "Usuario o contraseña incorrecta.",
             type:"error",   
             timer: 2000,   
             showConfirmButton: false 
        });
    })

   

  }

  ngOnInit() {
      var navbar : HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('login-page');
      body.classList.add('off-canvas-sidebar');
      const card = document.getElementsByClassName('card')[0];
      setTimeout(function() {
          // after 1000 ms we add the class animated to the login/register card
          card.classList.remove('card-hidden');
      }, 700);
  }
  sidebarToggle() {
      var toggleButton = this.toggleButton;
      var body = document.getElementsByTagName('body')[0];
      var sidebar = document.getElementsByClassName('navbar-collapse')[0];
      if (this.sidebarVisible == false) {
          setTimeout(function() {
              toggleButton.classList.add('toggled');
          }, 500);
          body.classList.add('nav-open');
          this.sidebarVisible = true;
      } else {
          this.toggleButton.classList.remove('toggled');
          this.sidebarVisible = false;
          body.classList.remove('nav-open');
      }
  }
  ngOnDestroy(){
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }

}
