import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { url} from '../intefaces/url';

import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  uri = url + 'user';
  userId= '';
  constructor(private http:Http) { }

  newReport(requests:any) {
    let request = requests;
    this.uri = '';
    this.uri = url;
    
    this.uri += 'reports/general'
    let body = request;
    let headers = new Headers({
      'content-Type': 'application/json',
    });
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res => res.json())
    );
  }
  packReport(requests:any){
    this.uri = '';
    // this.uri = url;
    this.uri += 'https://5poqwm6zbj.execute-api.us-east-1.amazonaws.com/dev/report'
    let headers = new Headers({
      
      'content-Type': 'application/json',
    });
    let body = requests;
 
    return this.http.post(this.uri, body, {headers: headers}).pipe(
      map(res =>res.json())
    );
  }
}
