import { Component, OnInit,AfterViewInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../intefaces/user.interface';
import { usu } from 'src/app/intefaces/usuario.interface';
import swal from 'sweetalert2';
declare interface DataTable {
  headerRow: string[];
  
  dataRows: string[];
}
declare const $: any;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit, AfterViewInit {

 
  users:any[] = [];
  type:string = '';
  dataTable = {
    headerRow: ['Nombre', 'Usuario' , 'Correo', 'Tipo','Editar'],
    dataRows: []
  };
  bandera= false;
  constructor(private userServices: UsersService,private router:Router) {
    // this.userServices.getUsers().subscribe(data =>{
    //   console.log(data);
    //   this.users = data.body;
    //   console.log(this.users);
    //   this.dataTable = {
    //     headerRow :['Nombre', 'Usuario' , 'Correo', 'Tipo'],
    //     dataRows: [this.users],
    //   };
    // });
   

   }
   edit(id:any){
    console.log(id)
    this.userServices.changeUserId(id);
    this.router.navigate(['/dashboard/User/edit']);
   }
   delete(user:User){
     user.status = false;
    let centinela: usu ={
      User: user
    }
    console.log(centinela)
    swal({
      title: 'Eliminar',
        text: "Seguro que quiere eliminar?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        confirmButtonText: 'Si',
         buttonsStyling: false
    }).then((result)=>{
      if (result.value) {
        this.bandera = false;
        this.userServices.putUser(centinela).subscribe(data =>{
          console.log(data);
        })
        swal(
          {
            title: 'Eliminado!',
            text: '',
            type: 'success',
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          }
        )
        }
      })

    //  this.userServices.putUser(centinela).subscribe(data =>{
    //    console.log(data)
       
    //    swal({
    //     title: 'Eliminar',
    //     text: "Seguro que quiere eliminar?",
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonClass: 'btn btn-success',
    //     cancelButtonClass: 'btn btn-danger',
    //     confirmButtonText: 'Si',
    //      buttonsStyling: false
    //   }).then((result) => {
    //     if (result.value) {
    //       this.bandera = false;
    //       swal(
    //         {
    //           title: 'Eliminado!',
    //           text: '',
    //           type: 'success',
    //           confirmButtonClass: "btn btn-success",
    //           buttonsStyling: false
    //         }
    //       )
    //     }
    //   })
      
    

       
    //  })

   }
  ngOnInit() {
  
  }
  tipo(){
    this.userServices.getUsers(this.type).subscribe(data=>{
      console.log(data);
      this.users= data;
     
      this.dataTable.dataRows = this.users; 
      this.bandera = true;
    })
  }
  ngAfterViewInit() {





    
  }

}
