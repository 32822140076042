import { Component, OnInit, ViewChild } from '@angular/core';

import { RequestService } from '../services/request.service'
import { Requests } from '../intefaces/requests.interface';
import { Details } from '../intefaces/details.interface';
import { Router } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import swal from 'sweetalert2';
import { interval } from 'rxjs';
import { SpinnerService } from '../services/spinner.service';

declare interface DataTable {
  headerRow: string[];
  
  dataRows: any[];
}
declare interface info {
  fecha:any,
  solicitud:any,
  identificacion:string,
  estado:string,
  valorT:any,
  valorS:any,
  financiera:String,

} 
declare const $: any;
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  public dataTable: DataTable;
  requests:Requests[]= [];
  displayedColumns: string[] = ['Clinica','Fecha', 'Titular', 'Identificación','Comercial','Analista' ,'Estado', 'Valor solicitado','Financiera','Detalles'];
  dataSource: MatTableDataSource<Requests>;
  paginator: MatPaginator;

  info1:info = {
    fecha:'2/05/20',
    solicitud:'sol',
    identificacion:'100000',
    estado:'activo',
    valorT:'2999',
    valorS:'30232',
    financiera: 'Denti salud'
  };
  info2:info = {
    fecha:'6/05/20',
    solicitud:'sol',
    identificacion:'340000',
    estado:'activo',
    valorT:'8989',
    valorS:'30232',
    financiera: 'Refinancia'
  };
  
  public timerInterval:any;
  
  constructor(private requestServices:RequestService, private router:Router,private spinnerService:SpinnerService) { 
    
    this.requestServices.getAllRequest().subscribe(data=>{
      this.requests =  data;
      //console.log(data);
      this.dataSource = new MatTableDataSource(this.requests);
      this.dataTable = {
        headerRow :['Clinica','Fecha', 'Titular', 'Identificación','Comercial','Analista' ,'Estado', 'Valor solicitado','Financiera','Detalles'],
        dataRows: this.requests,
      };
      
    })
    //console.log(this.requests)
    this.actualizar();
    
  }

  actualizarManual(){
    this.timerInterval.unsubscribe();
    this.requestServices.getAllRequest().subscribe(data =>{
      this.requests =  data;
      //console.log(data)
      this.dataSource = new MatTableDataSource(this.requests);
      
      
    })
  }
  x():any{
    //console.log('hola')
    // this.requestServices.getAllRequest().subscribe(data =>{
    //   this.requests =  data;
    //   //console.log(data)
    //   this.dataSource = new MatTableDataSource(this.requests);
    // })
  }
  actualizar():any{
    this.timerInterval =interval(60000).subscribe(x =>{
        this.requestServices.getAllRequest().subscribe(data =>{
        this.requests =  data;
        //console.log(data)
        this.dataSource = new MatTableDataSource(this.requests);
      },
      (error) =>{
        console.log(error)
        this.timerInterval.unsubscribe();
      }
      )
    })
    // setInterval( 
    //   this.x()
    
    // , 6000);
    
  }
  ngOnDestroy() {
    
    this.timerInterval.unsubscribe();
 }
  ngOnInit() {
    
    this.dataSource.paginator = this.paginator;
    this.requestServices.getAllRequest().subscribe(data=>{
      //console.log(data);
      this.requests =  data;
      this.dataSource = new MatTableDataSource(this.requests);
      this.dataTable = {
        headerRow :['Clinica','Fecha', 'Titular', 'Identificación','Comercial','Analista' ,'Estado', 'Valor solicitado','Financiera','Detalles'],
        dataRows: this.requests,
      };
    })
    
  }

  ngAfterViewInit() {
    
    $('#datatables').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Buscar asignación",
      },
      

    });
    const table = $('#datatables').DataTable();

     // Edit record
      table.on('click', '.edit', function(e) {
        const $tr = $(this).closest('tr');
        const data = table.row($tr).data();
        alert('You press on Row: ' + data[0] + ' ' + data[1] + ' ' + data[2] + '\'s row.');
        e.preventDefault();
      });

      // Delete a record
      table.on('click', '.remove', function(e) {
        const $tr = $(this).closest('tr');
        table.row($tr).remove().draw();
        e.preventDefault();
      });

      //Like record
      table.on('click', '.like', function(e) {
        alert('You clicked on Like button');
        e.preventDefault();
      });

      $('.card .material-datatables label').addClass('form-group');
      

    }

    saveDetails(id:string, loan:string,userId:string,timestamp:any){
     
      let details:Details = {
        loanId: loan,
        customerId: id,
        userId: userId
      }
      let cuerpo = {
        loanId:loan,
        timestamp:timestamp

      }
      this.requestServices.estadoDetails(cuerpo).subscribe(data =>{
        //console.log(data)
        if(data.userId != "NA"){
          $.notify({
            icon: 'notifications',
           message: "Esta solicitud ya esta asignada esta solicitud.",
          }, {
           type: 'warning',
           timer: 1000,
           placement: {
               from: 'top',
               align: 'right'
           },
           template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
           '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
           '<i class="material-icons" data-notify="icon">notifications</i> ' +
           '<span data-notify="title">{1}</span> ' +
           '<span data-notify="message">{2}</span>' +
           '<div class="progress" data-notify="progressbar">' +
             '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
           '</div>' +
           '<a href="{3}" target="{4}" data-notify="url"></a>' +
         '</div>'
          })
          this.actualizar();
          //console.log('no est asigando')
        }
        if(data.userId == "NA"){
          this.requestServices.changeDetails(details);
          this.router.navigate(['/dashboard/Request/id']);
        }
      })
      
    }

}
