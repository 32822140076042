import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ReportOne } from '../intefaces/reportOne.interface';
import { ReportsService} from '../services/reports.service'
import { ClinicsService} from '../services/clinics.service';
import { Clinic } from '../intefaces/clinic.interface';
import swal from 'sweetalert2';


declare interface Reporte{
  nombre_cliente: string,
  apellido_cliente: string,
  tasa:any,
  documento:any,
  apellido_paciente:string,
  nombre_paciente:any,
  estado:any,
  documento_paciente:any,
  zona:any,
  tiempo:any,
  descuento:any,
  clinica:any,
  financiera:any,
  codigo:any,
  fecha:any,
  cantidad:any,
  usuario:any,


}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  startDate= '';
  finDate= '';
  documentId ='';
  clinic ='';
  code = '';
  report: ReportOne[] = [];
  bandera = false;
  clinics:Clinic[] = [];
  reporte: Reporte[] = [];
  descargar = false;
  constructor(private reportServices:ReportsService,private clinicServices:ClinicsService) { 
    clinicServices.getClinics().subscribe(data =>{
      this.clinics = data;
      console.log(this.clinics);
    })
  }

  ngOnInit() {
  }

  arrayObjToCsv(ar) {
    //comprobamos compatibilidad
    if(window.Blob && (window.URL )){
      var contenido = "",
        d = new Date(),
        blob,
        reader,
        save,
        clicEvent;
      //creamos contenido del archivo
      for (var i = 0; i < ar.length; i++) {
        //construimos cabecera del csv
        if (i == 0)
          contenido += Object.keys(ar[i]).join(";") + "\n";
        //resto del contenido
        contenido += Object.keys(ar[i]).map(function(key){
                return ar[i][key];
              }).join(";") + "\n";
      }
      //creamos el blob
      blob =  new Blob(["\ufeff", contenido], {type: 'text/csv'});
      //creamos el reader
      var reader:any = new FileReader();
      reader.onload = function (event) {
        //escuchamos su evento load y creamos un enlace en dom
        save = document.createElement('a');
        save.href = event.target.result;
        save.target = '_blank';
        //aquí le damos nombre al archivo
        save.download = "Reporte.csv";
        try {
          //creamos un evento click
          clicEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
        } catch (e) {
          //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
          clicEvent = document.createEvent("MouseEvent");
          clicEvent.initEvent('click', true, true);
        }
        //disparamos el evento
        save.dispatchEvent(clicEvent);
        //liberamos el objeto window.URL
        (window.URL ).revokeObjectURL(save.href);
      }
      //leemos como url
      reader.readAsDataURL(blob);
    }else {
      //el navegador no admite esta opción
      alert("Su navegador no permite esta acción");
    }
  };
  
  ownloadFile() {
    for(let i =0; i< this.report.length; i++){
      delete this.report[i].reportId;
      delete this.report[i].loanId;
      delete this.report[i].customerId;
    }
    this.arrayObjToCsv(this.reporte);
  }
  consultar(){
    
   
    if(this.code ==='' && this.clinic === '' && this.documentId === ''){

     
      let start = moment(this.startDate).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';
      let date = new Date(this.finDate);
      console.log(date);
      date.setDate(date.getDate() + 1);
      
      
      console.log(date);
      this.finDate = moment(date).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';
      let res = {
        startDate: start,
        finDate: this.finDate,

        
      }
      console.log(res);
      this.reportServices.newReport(res).subscribe(data =>{
        console.log(data);
        this.report = data;
        this.bandera = true;

        console.log(this.report)
        this.report.map(element=>{
          let centinela:Reporte= {
           nombre_cliente: element.customerName,
           apellido_cliente:  element.customerSurname,
           tasa: element.rate,
           documento:element.documentId,
           apellido_paciente:element.pacientSurnames,
           nombre_paciente:element.pacientNames,
           estado:element.status,
           documento_paciente:element.pacientDocumentId,
           zona:element.zone,
           tiempo:element.time,
           descuento:element.discount,
           clinica:element.clinic,
           financiera:element.financial,
           codigo:element.code,
           fecha:element.date,
           cantidad:element.amount,
           usuario:element.userName,
          }
          this.reporte.push(centinela)
        })
        console.log(this.reporte)
        if(this.report.length == 0){
          swal({   title: "Ups!",   
          text: "No se encontraron resultados",
          type:"error",   
          timer: 2000,   
          showConfirmButton: false 
           });
       }
      })
      
      this.descargar = true;
      // this.startDate = '';
      // this.finDate = '';
      
     
    }
    else if(this.code ==='' && this.clinic === ''){
      let start  = moment(this.startDate).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';
      let date = new Date(this.finDate);
      console.log(date);
      date.setDate(date.getDate() + 1);
      
      
      console.log(date);
      this.finDate = moment(date).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';
      let res = {
        startDate: start,
        finDate: this.finDate,
        documentId: Number(this.documentId)
      }
      console.log(res);
      this.reportServices.newReport(res).subscribe(data =>{
        console.log(data);
        this.report = data;
        this.bandera = true;
        this.report.map(element=>{
          let centinela:Reporte= {
           nombre_cliente: element.customerName,
           apellido_cliente:  element.customerSurname,
           tasa: element.rate,
           documento:element.documentId,
           apellido_paciente:element.pacientSurnames,
           nombre_paciente:element.pacientNames,
           estado:element.status,
           documento_paciente:element.pacientDocumentId,
           zona:element.zone,
           tiempo:element.time,
           descuento:element.discount,
           clinica:element.clinic,
           financiera:element.financial,
           codigo:element.code,
           fecha:element.date,
           cantidad:element.amount,
           usuario:element.userName,
          }
          this.reporte.push(centinela)
        })
        console.log(this.reporte)
        if(this.report.length == 0){
          swal({   title: "Ups!",   
          text: "No se encontraron resultados",
          type:"error",   
          timer: 2000,   
          showConfirmButton: false 
           });
       }
      })
      this.descargar = true;
      
      
    }
    else if( this.documentId === '' && this.clinic === '' ){
       // console.log(moment(this.startDate).format("DD-MM-YYYY-MM"));
       let start = moment(this.startDate).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';

     let date = new Date(this.finDate);
     console.log(date);
     date.setDate(date.getDate() + 1);
     
     
     console.log(date);
     this.finDate = moment(date).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';
     
     let res = {
       startDate: start,
       finDate: this.finDate,
       code: this.code
     }
     console.log(res);
     this.reportServices.newReport(res).subscribe(data =>{
       console.log(data);
       this.report= data;

       this.report.map(element=>{
         let centinela:Reporte= {
          nombre_cliente: element.customerName,
          apellido_cliente:  element.customerSurname,
          tasa: element.rate,
          documento:element.documentId,
          apellido_paciente:element.pacientSurnames,
          nombre_paciente:element.pacientNames,
          estado:element.status,
          documento_paciente:element.pacientDocumentId,
          zona:element.zone,
          tiempo:element.time,
          descuento:element.discount,
          clinica:element.clinic,
          financiera:element.financial,
          codigo:element.code,
          fecha:element.date,
          cantidad:element.amount,
          usuario:element.userName,
         }
         this.reporte.push(centinela)
       })
       console.log(this.reporte)
       this.bandera = true;
       if(this.report.length == 0){
        swal({   title: "Ups!",   
        text: "No se encontraron resultados",
        type:"error",   
        timer: 2000,   
        showConfirmButton: false 
         });
     }
     })
     
     this.descargar = true;
    

    }
   else if(this.code ==='' && this.documentId === ''){
    let start = moment(this.startDate).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';
      let date = new Date(this.finDate);
      console.log(date);
      date.setDate(date.getDate() + 1);
      
      
      console.log(date);
      this.finDate = moment(date).format("YYYY-MM-DD").toString() + 'T00:00:00-00:00';
      let res = {
        startDate: start,
        finDate: this.finDate,
        clinic: this.clinic
      }
      console.log(res);
      this.reportServices.newReport(res).subscribe(data =>{
        console.log(data);
        this.report= data;
        this.bandera = true;
        this.report.map(element=>{
          let centinela:Reporte= {
           nombre_cliente: element.customerName,
           apellido_cliente:  element.customerSurname,
           tasa: element.rate,
           documento:element.documentId,
           apellido_paciente:element.pacientSurnames,
           nombre_paciente:element.pacientNames,
           estado:element.status,
           documento_paciente:element.pacientDocumentId,
           zona:element.zone,
           tiempo:element.time,
           descuento:element.discount,
           clinica:element.clinic,
           financiera:element.financial,
           codigo:element.code,
           fecha:element.date,
           cantidad:element.amount,
           usuario:element.userName,
          }
         
          this.reporte.push(centinela)
        })
        console.log(this.reporte)
        if(this.report.length == 0){
          swal({   title: "Ups!",   
          text: "No se encontraron resultados",
          type:"error",   
          timer: 2000,   
          showConfirmButton: false 
           });
       }
      })
      
      this.descargar = true;
      
    
    }
    
  }
  

}
